import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { decode } from 'he';

import Layout from '../components/Layout';

import { Container, Card } from '@ao-internal/components-react';

export interface AboutProps {
	data: {
		wpPage: {
			title: string;
			content: string;
		};
		allWpUser: {
			edges: {
				node: PostAuthor;
			}[];
		};
	};
}

export const pageQuery = graphql`
	query aboutPageQuery {
		wpPage(id: { eq: "cG9zdDoy" }) {
			title
			content
		}
		allWpUser {
			edges {
				node {
					name
					description
					avatar {
						url
					}
				}
			}
		}
	}
`;

export const AboutPage: React.SFC<AboutProps> = ({
	data: { wpPage, allWpUser },
}) => (
	<React.Fragment>
		<Helmet title={decode(wpPage.title)} />

		<Container className="blog-page">
			<Card>
				<h1 className="text-display text-center mb-8 blog-page__header">
					{wpPage.title}
				</h1>

				<div className="blog-page__contents">
					<div className="blog-page__body">
						<div
							className="blog-post__tex blog-page__tex text-body"
							dangerouslySetInnerHTML={{
								__html: wpPage.content,
							}}
						/>

						<div className="blog-post__tex blog-page__authors text-body">
							<h2 className="text-display-sm">Contributors</h2>
							<ul>
								{allWpUser.edges.map((author, i) => (
									<li key={`author-${i}`}>
										<div className="flex items-center author__container">
											<div className="flex-shrink mr-4">
												<div
													className="author__avatar"
													style={
														author.node.avatar && {
															backgroundImage: `url(${author.node.avatar.url})`,
														}
													}
												/>
											</div>
											<div className="flex-grow">
												<h3 className="text-display-sm author__name">
													{author.node.name}
												</h3>
												<span className="text-caption block author__description">
													{author.node.description}
												</span>
											</div>
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</Card>
		</Container>
	</React.Fragment>
);

export default function (props: AboutProps) {
	return (
		<Layout>
			<AboutPage {...props} />
		</Layout>
	);
}
